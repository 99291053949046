<template>
  <div class="main">
    <div class="app-wrapper">
      <div class="machine_title">{{ $t("Machine.Equipment") }}</div>
      <div class="machine_search">
        <div class="search_left">
          <div class="machine_search_list">
            <div class="search_title">{{ $t("Machine.Name") }}</div>
            <div class="search_selected">
              <el-input
                :label="$t('AddMachine.GroupSimple')"
                v-model.trim="key"
                :placeholder="$t('Warning.inputName')"
                clearable
              />
            </div>
          </div>

          <el-button round type="primary" @click="handleSearch" size="small">{{
            $t("Machine.Search")
          }}</el-button>
          <el-button round type="primary" @click="handleReset" size="small">{{
            $t("Warning.Reset")
          }}</el-button>
        </div>
        <div class="search_right">
          <el-button round type="primary" @click="handleAdd" size="small">
            {{ $t("Machine.Add") }}
          </el-button>
        </div>
      </div>
      <div class="table_content">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          :header-cell-style="{ background: '#dde0e8' }"
          highlight-current-row
          style="width: 100%"
        >
          <el-table-column width="50" align="center" />
          <el-table-column :label="$t('Custom.Order')">
            <template slot-scope="{ row, $index }">
              {{ (page - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('Machine.Name')"
            prop="name"
          ></el-table-column>
          <el-table-column
            :label="$t('VersionManage.Version')"
            prop="version"
          ></el-table-column>
          <el-table-column
            :label="$t('VersionManage.PackageName')"
            prop="file"
            width="350"
          ></el-table-column>
          <el-table-column :label="$t('VersionManage.VersionType')">
            <template slot-scope="{ row }">
              {{
                row.type
                  ? $t(
                      `VersionManage.${
                        ["FrontEndPackage", "BackEndPackage"][row.type - 1]
                      }`
                    )
                  : "--"
              }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('Setting.CreationTime')">
            <!-- filters -->
            <template slot-scope="{ row }">
              {{ row.createtime | time }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('VersionManage.Remark')"
            prop="remark"
          ></el-table-column>
          <el-table-column
            l:label="$t('AddMachine.Operation')"
            width="120"
            align="left"
          >
            <template slot-scope="scope">
              <img
                src="../../assets/image/bianji_main@2x.png"
                @click="handleEdit(scope.row)"
                class="OperationImg mgr"
              />
              <img
                src="../../assets/image/shanchu_mian@2x.png"
                @click="handleDelete(scope.row.id)"
                class="OperationImg mgr"
              />
            </template>
          </el-table-column>

          <el-table-column width="20" align="center" />
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :page-size="pageSize"
          :page-sizes="[5, 10, 15]"
          :current-page.sync="page"
          :total="total"
          v-show="total && total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      class="dialog"
      :close-on-click-modal="false"
      :before-close="handleCloseDialog"
    >
      <el-form
        ref="workShopForm"
        :model="formData"
        label-width="0px"
        :rules="rules"
      >
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label" style="text-align: left">
              {{ $t("Machine.Name") }}
            </div>
            <el-input
              v-model.trim="formData.name"
              :placeholder="$t('Warning.inputName')"
              clearable
            />
          </el-form-item>
        </div>
        <div class="form-box">
          <el-form-item prop="version">
            <div class="label" style="text-align: left">
              {{ $t("VersionManage.Version") }}
            </div>
            <el-input
              v-model.trim="formData.version"
              :placeholder="$t('VersionManage.InputVersion')"
              clearable
            />
          </el-form-item>
        </div>

        <div class="form-box">
          <el-form-item prop="type">
            <div class="label" style="text-align: left">
              {{ $t("VersionManage.VersionType") }}
            </div>
            <el-select
              v-model="formData.type"
              :placeholder="$t('VersionManage.InputVersionType')"
              style="width: 100%"
            >
              <el-option :label="$t('VersionManage.FrontEndPackage')" value="1">
              </el-option>
              <el-option :label="$t('VersionManage.BackEndPackage')" value="2">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-box">
          <el-form-item prop="file">
            <div class="label" style="text-align: left">
              {{ $t("VersionManage.UploadFile") }}
            </div>
            <div style="display: flex">
              <el-input
                style="flex: 4"
                v-model="formData.file"
                :placeholder="$t('VersionManage.PlsUploadFile')"
                readonly
                @focus="handleUpload"
              />

              <!--   accept=".zip"-->
              <el-upload
                action="#"
                class="upload-demo"
                :show-file-list="false"
                auto-upload
                :http-request="handleFileUpload"
                :file-list="fileList"
                :on-change="handleFileChange"
                ref="upload"
                accept=".zip"
              >
                <el-button size="small" :disabled="uploadLoading">{{
                  uploadLoading
                    ? $t("VersionManage.uploading")
                    : $t("VersionManage.ClickUpload")
                }}</el-button>
              </el-upload>
            </div>
          </el-form-item>
        </div>
        <div class="form-box">
          <el-form-item prop="remark">
            <div class="label" style="text-align: left">
              {{ $t("VersionManage.Remark") }}
            </div>
            <el-input
              v-model="formData.remark"
              :placeholder="$t('VersionManage.InputRemark')"
              clearable
            />
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div
          class="btn-normal pan-btn blue-btn"
          style="margin-right: 0px; background: #4875ef"
          @click="handleConfirm"
        >
          {{ $t("Login.OK") }}
        </div>
        <div
          class="btn-normal pan-btn tiffany-btn"
          @click="dialogVisible = false"
          style="background: #767e95"
        >
          {{ $t("AddMachine.Cancel") }}
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import "../../style/table.less";
import {
  getVersionList,
  uploadFile,
  addVersion,
  deleteVersion,
} from "@/api/layout";
import { awaitWraper, isEmpty } from "@/js/util";
export default {
  data() {
    return {
      dialogVisible: false,
      title: "",
      formData: {
        file: "", //上传文件成功后返回的地址
        name: "",
        type: "",
        remark: "",
        version: "",
        id: "",
      },
      workShopId: "",
      password: "",
      type: "add",
      tableData: [],
      total: 0,
      key: "",
      page: 1,
      pageSize: 15,
      editForm: {},
      fileList: [], //上传文件列表
      rules: {
        name: [
          {
            required: true,
            message: this.$t("Warning.inputName"),
            trigger: "blur",
          },
        ],
        version: [
          {
            required: true,
            message: this.$t("VersionManage.InputVersion"),
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("VersionManage.InputVersionType"),
            trigger: "change",
          },
        ],
        file: [
          {
            required: true,
            message: this.$t("VersionManage.PlsUploadFile"),
            trigger: "change",
          },
        ],
      },
      uploadLoading: false,
      upladingFile: null,
    };
  },
  mounted() {
    this.getTableData();
  },
  filters: {
    time(item) {
      const date = new Date(item * 1000);
      const month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      const hour =
        date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
      const minute =
        date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
      return (
        date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute
      );
    },
  },
  methods: {
    handleSearch() {
      if (isEmpty(this.key)) {
        return this.$message({
          showClose: true,
          message: this.$t("Tip.Criteria"),
          type: "warning",
          duration: 1000,
        });
      }
      this.getTableData(1);
    },
    async getTableData(number) {
      if (!isEmpty(number) && !Number.isNaN(number)) {
        this.page = number;
      }
      // showLoading
      const [err, res] = await awaitWraper(
        getVersionList({
          page: this.page,
          pagesize: this.pageSize,
          key: this.key,
        })
      );
      //   closeLoading
      if (err) {
        this.noDataTable = true;
        return;
      }
      this.tableData = res.data.list;
      this.total = res.data.count;
      this.noDataTable = this.total === 0;
    },
    handleAdd() {
      this.type = "add";
      this.title = this.$t("Machine.Add");
      //  清空表单
      this.dialogVisible = true;
      for (let key of Object.keys(this.formData)) {
        this.formData[key] = "";
      }
      this.$nextTick(() => {
        this.$refs["workShopForm"].clearValidate();
      });
    },
    handleEdit(row) {
      this.type = "edit";
      this.title = this.$t("Machine.Edit");
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["workShopForm"].clearValidate();
      });
      for (let key of Object.keys(this.formData)) {
        this.formData[key] = row[key];
      }
    },
    handleDelete(id) {
      this.$confirm(
        this.$t("VersionManage.ConfirmDelete"),
        this.$t("Tip.Tips"),
        {
          confirmButtonText: this.$t("Login.OK"),
          cancelButtonText: this.$t("AddMachine.Cancel"),
          type: "warning",
        }
      ).then(() => {
        deleteVersion({ id }).then(() => {
          this.$message({
            type: "success",
            message: this.$t("Setting.RemovedSuccessfully"),
          });
          this.getTableData(1);
        });
      });
    },
    handleReset() {
      this.key = "";
      this.getTableData(1);
    },

    async handleConfirm() {
      this.$refs["workShopForm"].validate(async (valid) => {
        if (valid) {
          let params;
          if (this.type == "add") {
            const { id, ...otherParams } = this.formData;
            params = otherParams;
          } else {
            params = this.formData;
          }
          params.package = params.file;
          delete params.file;
          const [err, res] = await awaitWraper(addVersion(params));
          if (err) {
            return;
          }
          this.dialogVisible = false;
          this.getTableData();
          this.$message(res.message, "success");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 做一下节流、防抖

    handleSizeChange(val) {
      this.page = 1;
      this.pageSize = val;
      this.getTableData(1);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getTableData();
    },
    handleUpload() {
      // this.$refs.upload.upload();
    },
    // handleBeforUpload(file) {
    //   console.log(file);
    //   // return false;
    // },
    // 处理文件上传操作
    handleFileUpload(file) {
      //   file.file.name
      //   this.loading = true;
      //   // 调用后端服务器的接口
      var formData = new FormData();
      formData.append("file", file.file);
      this.uploadLoading = true;
      uploadFile(formData)
        .then((resp) => {
          //   this.form.installImgUrl = resp.url;
          this.formData.file = resp.data.url;
        })
        .catch((e) => {
          // this.$message.error(e.message);
          this.$refs.upload.clearFiles();
        })
        .finally(() => {
          this.uploadLoading = false;
        });
    },
    handleFileChange(file, fileList) {
      this.upladingFile = file;
      if (fileList.length > 1) {
        fileList[0] = fileList[1];
        fileList.splice(1, 1);
      }
      console.log(fileList);
    },
    async handleCloseDialog(done) {
      if (this.uploadLoading) {
        return this.$message({
          showClose: true,
          message: this.$t("VersionManage.uploading"),
          type: "warning",
          duration: 1000,
        });
      }
      done();
    },
  },
};
</script>
<style lang="less">
.upload-demo {
  flex: 1;
  margin-top: -3px;
  padding-right: 15px;
  .el-upload {
    width: 100%;
    .el-button {
      width: 100% !important;
    }
  }
}
</style>

<style lang="less" scoped>
.mgr {
  margin-right: 0px !important;
  margin-left: 20px;
}
@media (max-width: 1367px) {
  .machine_search_list {
    flex: 1.75 !important;
  }
  .dialog /deep/ .el-input__suffix {
    right: 20px !important;
  }
}
@media (min-width: 1367px) and (max-width: 1441px) {
  .machine_search_list {
    flex: 1.45 !important;
  }
  .dialog /deep/ .el-input__suffix {
    right: 20px !important;
  }
}
@media (min-width: 1441px) and (max-width: 1661px) {
  .machine_search_list {
    flex: 1.7 !important;
  }
}
@media (min-width: 1661px) and (max-width: 1919px) {
  .machine_search_list {
    flex: 1.6 !important;
  }
}
.pagination-container {
  margin: 20px 25px;
  float: left;
}
.dialog /deep/ .el-form-item__content {
  line-height: 35px;
  font-size: 18px;
}
.dialog /deep/ .el-dialog {
  border-radius: 20px !important;
  margin-top: 20vh !important;
  background: #e7e9ee;
  filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.16));
}
.dialog /deep/ .el-dialog__body {
  padding: 0px 20px 10px 20px;
}
.btn-normal {
  width: 70px;
  height: 32px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 34px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  float: right;
  margin-left: 10px;
  cursor: pointer;
}
.dialog /deep/ .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}
.dialog /deep/ .plan-form.r3 .label {
  font-size: 16px;
}
.dialog /deep/ .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}
.dialog /deep/ .plan-form .row .form-item {
  width: 33.4% !important;
}
.dialog /deep/ .el-dialog__title {
  font-weight: bold;
  font-size: 20px;
}
.dialog /deep/ .el-dialog__body {
  padding: 20px 20px;
}
.dialog /deep/ .el-dialog__header {
  padding: 20px 20px 10px !important;
  text-align: left;
}
.dialog /deep/ .el-dialog__headerbtn {
  font-size: 30px !important;
  top: 12px;
}
.dialog /deep/ .el-input__suffix {
  right: 20px;
}
.dialog /deep/ .el-dialog__footer {
  padding: 0px 20px 15px !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #ebecee;
  /deep/ .el-button {
    min-width: 70px;
  }
  .app-wrapper {
    background-color: #ebecee;
    height: 100%;
    .table_content {
      height: calc(100vh - 200px);
      overflow: auto;
      /deep/ .el-table__body-wrapper {
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;

        background-color: #ebecee;
      }
      /deep/ .el-table tr {
        // background-color: #ebecee !important;
        cursor: pointer;
      }
    }
    .machine_title {
      padding: 22px 20px;
      color: #414141;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }
    .machine_search {
      display: flex;
      flex-direction: row;
      padding: 0px 25px;
      justify-content: space-between;
      align-items: center;
      .search_left {
        display: flex;
        gap: 20px;
        align-items: center;
      }
      .machine_search_list {
        flex: 1.25;
        .search_title {
          float: left;
          text-align: left;
          height: 32px;
          line-height: 32px;
          margin: 10px 10px 10px 0;
          color: #4d4f5c;
          font-size: 18px;
        }
        .search_selected {
          float: left;
          margin: 10px 10px 10px 0;

          /deep/ .el-date-editor.el-input,
          /deep/ .el-date-editor.el-input__inner {
            width: 100%;
          }
          /deep/ .el-input__inner {
            height: 32px;
            line-height: 32px;
          }
          /deep/ .el-input__icon {
            margin-top: 5px;
            color: #a4afb7;
            font-weight: bold;
          }
          /deep/ .el-input__suffix-inner .el-input__icon {
            margin-top: -2px;
          }
          /deep/ .input-wrapper .el-input__icon {
            margin-top: 2px;
          }
          /deep/ .el-select .el-input .el-select__caret.is-reverse {
            margin-top: -3px;
          }
        }
      }
      .machine_search_btn {
        flex: 0.3;
        .search_btn {
          width: 70px;
          height: 31px;
          background: #4875ef;
          font-size: 13px;
          color: #f2f3f6;
          text-align: center;
          line-height: 31px;
          border-radius: 30px;
          cursor: pointer;
        }
        .edi_color {
          background-color: #767e95;
        }
      }
      .machine_search_space {
        flex: 5.4;
      }
    }
  }
}
</style>
